<template>
<div class="modal-wrap">
  <h1 class="tit-h1 d-flex">
    금칙어 상세정보
    <div class="ml-auto align-self-center">
      <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
    </div>
  </h1>
  <div class="modal-box">
    <div>
      <v-form ref="form"
              v-model="isFormValid"
              lazy-validation
      >
        <div class="table-form">
          <table>
            <caption class="hide">
              정보 항목
            </caption>
            <colgroup>
              <col width="127px" />
              <col width="" />
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">금칙어코드</v-badge>
              </th>
              <td colspan="3">
                <v-text-field
                    name="GROUP_CD"
                    v-model="formWordData.PROH_CODE"
                    class="form-inp full"
                    maxlength="100"
                    hide-details="auto"
                    outlined
                    required
                    readonly
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">금칙어</v-badge>
              </th>
              <td colspan="3">
                <v-text-field
                    name="PROH_WORD"
                    v-model="formWordData.PROH_WORD"
                    class="form-inp full"
                    maxlength="100"
                    hide-details="auto"
                    outlined
                    required
                    :rules="[formRules.newWord.required]"
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">변환어</v-badge>
              </th>
              <td colspan="3">
                <v-text-field
                    name="CHANGE_WORD"
                    v-model="formWordData.CHANGE_WORD"
                    class="form-inp full"
                    maxlength="100"
                    hide-details="auto"
                    outlined
                    required
                    :rules="[formRules.chWord.required]"
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                등록자명
              </th>
              <td colspan="3">
                <v-text-field
                    name="REGR_NAME"
                    v-model="formWordData.REGR_NAME"
                    class="form-inp full"
                    maxlength="100"
                    hide-details="auto"
                    outlined
                    required
                    readonly
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                등록일자
              </th>
              <td colspan="3">
                <v-text-field
                    name="REGR_DTTM"
                    v-model="formWordData.REGR_DTTM"
                    class="form-inp full"
                    maxlength="100"
                    hide-details="auto"
                    outlined
                    required
                    readonly
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">사용여부</v-badge>
              </th>
              <td colspan="3">
                <v-select
                    name="USE_YN"
                    :items="mixin_common_code_get(this.common_code, 'TWB001')"
                    class="form-inp full"
                    outlined
                    hide-details
                    placeholder="사용여부"
                    v-model="formWordData.USE_YN"
                    required
                    :rules="[formRules.useYn.required]"
                >
                </v-select>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </v-form>
      <div class="text-right mt-3">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" id="btnSave" @click="submit">저장</v-btn>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import api from "../../store/apiUtil";
import { mixin } from "@/mixin/mixin.js";
import { mapGetters } from "vuex";

let jsonObj

export default {
  name: "MENU_M810608P02", //name은 'MENU_' + 파일명 조합
  mixins:[mixin],
  props: {
    param: {
      type: Object,
    },
    searchCustKey: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      common_code: [],
      formWordData: {},
      isFormValid: true,
      formRulesSubfix: ' 은(는) 필수입력입니다.',
      formRules: {
        newWord: {
          required: v => !!v || '금칙어' + this.formRulesSubfix
        },
        chWord: {
          required: v => !!(v  && (v+'').trim()!=='') || '변환어' + this.formRulesSubfix
        },
        useYn: {
          required: v => !!(v  && (v+'').trim()!=='') || '사용여부' + this.formRulesSubfix
        }
      },
    }
  },
  mounted() {
    this.initData()
  },
  methods:{
    async initData(){
      if(this.isEmpty(this.searchCustKey)){
        this.common_alert('회사구분 코드가 존재하지 않습니다.', 'error')
        this.$emit('hideAndGetList');
      }
      this.$refs.form.resetValidation()
      this.formWordData=JSON.parse((JSON.stringify(this.param)))
      let codeName = ["TWB001"];
      this.common_code= await this.mixin_common_code_get_all(codeName);
    },
    async submit(){

      if(!this.$refs.form.validate()) return false

      let URLData = "/api/chat/setting/banned-word/modify";
      let HEADER_SERVICE = "chat.setting.banned-word";
      let HEADER_METHOD = "modify";
      let HEADER_TYPE = "BIZ_SERVICE";



      let jsonData = {
        MODIFY_PROH_CODE: this.formWordData.PROH_CODE,
        MODIFY_PROH_WORD: this.formWordData.PROH_WORD,
        MODIFY_CHANGE_WORD: this.formWordData.CHANGE_WORD,
        USE_YN: this.formWordData.USE_YN,
        USER_NM: this.userId,
        USER_ID: this.userId,
        USER_DEPT_CD: 'x',
        ASP_NEWCUST_KEY: this.searchCustKey
      };

      //api 호출 부분
      await api
          .post(URLData, jsonData, {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE
            },
          })
          .then((response) => {
            if(!this.isEmpty(response) && !response.data.HEADER.ERROR_FLAG) {
              this.common_alert('정상처리 되었습니다.', 'done')
              this.$emit('hideAndGetList');
            } else {
              this.common_alert(response.data.HEADER.ERROR_MSG, 'error')
            }
          })
          .catch((err) => {
            alert(err);
          });
    },
    isEmpty(val) {
      if (val == null) return true
      if (typeof val === 'undefined') return true
      if (typeof val === 'string' && val === '' && (''+val) === '') return true
      if (Array.isArray(val) && val.length < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'Object' && Object.keys(val).length < 1 && Object.getOwnPropertyNames(val) < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'String' && Object.keys(val).length < 1) return true
    },
  },
  computed: {
    ...mapGetters({
      userId: 'userStore/GE_USER_ID',
      userNm: 'userStore/GE_USER_MENU'
    }),
  },
};
</script>

<style></style>
